/****************************************/
/********** Scripts  *************/
/****************************************/
/* Description :                        */
/*  - Script pour ajout automatique     */
/*	  de tag GTM sur le form            */
/* Responsabilité :                     */
/*  - Equipe DIGITALE CM-CIC/AM         */
/*  - MAJ Piano - 2023-03-16 - GH       */
/****************************************/
/****************************************/

/*global dataLayer*/

var trackForm = {
  getFormData: function () {
    return $('.hero__form').data('form');
  },
  checkUrl: function () {
    var url = window.location.href.split('#');

    // On regarde si l'url est bien coupé à partir d'un # et que frm est bien inclus
    if (url[1] !== undefined) {
      // On vérifie que le form a bien été validé en cherchant la classe formComplete

      if (url[1].indexOf('frm') > -1 || url[1].indexOf('formulaire') > -1 || url[1].indexOf('form') > -1) {
        if ($('.i_blocgenform > .formComplete, .hero__form.completed, .i_blocgenform > .form__success')) {
          this.pushDataLayer();
        }

        const newModel = document.querySelector('.hero__form');
        if (!!newModel) {
          newModel.classList.add('completed');
        }
      }
    }
  },
  pushDataLayer: function () {
    console.log('création du pushDataLayer');
    if (typeof dataLayer === 'undefined') {
      dataLayer = [];
    }

    console.log('le data', this.getFormData());
    // On déclenche le bon push datalayer en fonction de la valeur du data-form
    switch (this.getFormData()) {
      case 'wcb':
        console.log('push WCB');
        if (typeof(dataLayer) != "undefined"){
          dataLayer.push({
            event: 'customEvent',
            customCategory: 'DemandeDeContact',
            customAction: 'WCB',
            customLabel: 'Confirmation',
          });
        }

        if(typeof(trackEvent) !='undefined'){
          trackEvent({
            category:'DemandeDeContact',
            action:'WCB',
            label:'Confirmation'
          });
        }
        break;

      case 'mail':
        if (typeof(dataLayer) != "undefined"){
          dataLayer.push({
            event: 'customEvent',
            customCategory: 'DemandeDeContact',
            customAction: 'Mail',
            customLabel: 'Confirmation',
          });
        }

        if(typeof(trackEvent) !='undefined'){
          trackEvent({
            category:'DemandeDeContact',
            action:'Mail',
            label:'Confirmation'
          });
        }
        break;

      case 'dataCollect':
        if (typeof(dataLayer) != "undefined"){
          dataLayer.push({
            event: 'customEvent',
            customCategory: 'AnimationCommerciale',
            customAction: 'CollecteDeDonnees',
            customLabel: 'Confirmation',
          });
        }

        if(typeof(trackEvent) !='undefined'){
          trackEvent({
            category:'AnimationCommerciale',
            action:'CollecteDeDonnees',
            label:'Confirmation'
          });
        }
        break;

      default:
        if (typeof(dataLayer) != "undefined"){
          dataLayer.push({
            event: 'customEvent',
            customCategory: 'DemandeDeContact',
            customAction: 'WCB',
            customLabel: 'Confirmation',
          });
        }

        if(typeof(trackEvent) !='undefined'){
          trackEvent({
            category:'DemandeDeContact',
            action:'WCB',
            label:'Confirmation'
          });
        }
        break;
    }
  },
  init: function () {
    this.checkUrl();
  },
};

document.addEventListener('DOMContentLoaded', function () {
  trackForm.init();
});
